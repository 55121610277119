h1 {
    color: var(--nav-color-hover);
}
.main-info {
    display: flex;
    height: 100vh;
    color: var(--nav-color-hover);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}