.about__container {
    position: relative;
    margin: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    width: 100%;
    padding: 40px;
}

.about__text {
    max-width: 600px;
}

.about__container img {
    border-radius: 50%;
    border: 0.5px rgb(218,218,218);
    margin-bottom: 1rem;
    max-width: 200px;
    max-height: 200px;
    object-fit: scale-down;
}

/* --------Media Queries-------- */

@media only screen and (max-width: 900px) {
    .about__container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) {
    .about__text {
        max-width: 300px;
    }
}