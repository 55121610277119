.navigation_container {
  background: var(--font-color);
  color: var(--font-color-hover);
  }

  .navbar-light .navbar-nav .nav-link {
    color: var(--font-color-hover) !important;
}

.navbar-light .navbar-brand {
  color: var(--font-color-hover) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
  color: var(--font-color-hover);
}

.links a:hover {
  /* background-color: var(--light-color); */
  color: red !important;
  border-radius: 9px;
}