@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap');


.App {
  font-family: 'Nunito', sans-serif;
}

:root {
  --nav-color: #A3D2CA;
  --dark-color: #5EAAA8;
  --orange: #EB5E0B;
  --nav-color-hover: hsl(210, 11%, 45%);
  --nav-borer-color: hsl(210, 11%, 45%);
  --light-color: #A3D2CA;
  --font-color: #F8F1F1;
  --nav-hover: #F8EDE3;
  --font-color-hover: hsl(0, 0%, 30%);
  --gradient: linear-gradient(270deg, #FFC000 8.74%, #EB0000 53.4%, #0000FF 100%);
}

.tsparticles-canvas-el {
  position: inherit !important;
  min-height: 96vh;
}

#tsparticles {
  height: 100vh;
}