  .portfolio__main__container {
    background-color: white;
    padding: 10px;
  }
   
  .portfolio__main__container h1, 
  .portfolio__main__container p {
    text-align: center;
    margin-top: 3rem;
  }
   
  .portfolio__card__container {
    width: 50%;
    padding: 10px;
    border: none;
  }

  /* .container {
    grid-template-columns: 100px 50px 100px;
    grid-template-rows: 80px auto 80px; 
    column-gap: 50px;
    row-gap: 55px;
  } */
   
  .portfolio__image:hover {
    cursor: pointer;
    filter: brightness(50%);
  }
   
  .portfolio__click__info {
    position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%);
    color: var(--font-color);
    display: none;
  }
   
  .portfolio__image:hover + .portfolio__click__info {
    display: block;
  }
   
  .modal-backdrop.show {
    opacity: .1 !important;
  }
   
  #portfolio__modal__link {
    padding: 0 0 1rem 1rem
  }
   
  #portfolio__modal__link:link {
    text-decoration: none;
  }
   
  @media only screen and (max-width: 600px){
    #portfolio__card__container {
      width: 100%;
      padding: 0.5rem 2.5rem;
      border: none;
    }
  }
  