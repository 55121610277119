.technologies__container {
    color: var(--nav-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    z-index: 2;
}

.tech__cards__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem 0;
    z-index: 2;
}

.tech__card {
    width: 10rem;
    height: 7rem;
    background-color: white;
    color: var(--nav-color-hover);
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border-color: var(--nav-color);
    border-style: solid;
    border-width: 1px 1px 5px 1px;
    transition: all 0.1s;
}

.tech__icons {
    height: 3rem;
    background: linear-gradient(to left top, var(--light-color), #9198e5, #c59494);
    border-radius: 50%;
}